import React, { useState, useEffect } from 'react';
import { Form, Badge } from 'react-bootstrap';

export function RosterStatusSelector(props) {
    const [rosterStatuses, setRosterStatuses] = useState(['0']);

    useEffect(() => {
        props.setRosterStatus(rosterStatuses);
    }, [rosterStatuses])

    function addRosterStatus(rosterStatus) {
        if (!rosterStatuses.includes(rosterStatus)) {
            let curRosterStatuses = rosterStatuses.slice();
            if (rosterStatus !== '0') {
                curRosterStatuses.push(rosterStatus);
                if (curRosterStatuses.length > 1 && curRosterStatuses.includes('0')) {
                    curRosterStatuses.splice(curRosterStatuses.indexOf('0'), 1);
                }
                setRosterStatuses(curRosterStatuses);
            }
            else {
                setRosterStatuses(['0']);
            }
        }
    };

    function remRosterStatus(rosterStatus) {
        let curRosterStatuses = rosterStatuses.slice();
        if (curRosterStatuses.includes(rosterStatus)) {
            curRosterStatuses.splice(curRosterStatuses.indexOf(rosterStatus), 1);
        }
        setRosterStatuses(curRosterStatuses);
    }

    let selectedRosterStatuses = [];
    for (let i = 0; i < rosterStatuses.length; i++) {
        if (rosterStatuses[i] !== '0') {
            selectedRosterStatuses.push(
                <Badge key={rosterStatuses[i]} variant='secondary'>{rosterStatusValues[rosterStatuses[i]]}<span type='button' onClick={() => remRosterStatus(rosterStatuses[i])}>X</span></Badge>
            )
        }
        else {
            selectedRosterStatuses.push(
                <Badge key={rosterStatuses[i]} variant='secondary'>{rosterStatusValues[rosterStatuses[i]]}</Badge>
            )

        }
    };

    return (
        <React.Fragment>
            <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>End of Season Roster Status: </Form.Label>
            <Form.Control style={{ width: '187px' }} as='select' onChange={(event) => addRosterStatus(event.target.value)}>
                <option key='0' value='0'>All</option>
                <option key='1' value='1'>Active</option>
                <option key='2' value='2'>IL</option>
                <option key='3' value='3'>Ineligible</option>
                <option key='4' value='4'>Restricted</option>
                <option key='5' value='5'>Optioned</option>
                <option key='6' value='6'>Not on 40-man</option>
                <option key='7' value='7'>Unknown</option>
            </Form.Control>
            <div>
                {
                    selectedRosterStatuses.length > 0 &&
                    selectedRosterStatuses
                }
            </div>
        </React.Fragment>
    )
}

let rosterStatusValues = [
    'All',
    'Active',
    'IL',
    'Ineligible',
    'Restricted',
    'Optioned',
    'Not on 40-man',
    'Unknown',
]