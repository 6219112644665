import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Button, Form, Badge, Row, Col } from 'react-bootstrap';
import DatePicker from '../Common/DatePicker';
import { useStateWithCallbackLazy } from '../Common/CustomHooks';
import { TeamSelector } from '../Common/TeamSelector';
import { MonthSelector } from '../Common/MonthSelector';
import PlayingTimeFilterWrapper from './PlayingTimeFilterWrapper';
import MultiSelect from '../Common/MultiSelect';
import { RosterStatusSelector } from '../Common/RosterStatusSelector';

function LeaderboardForm(props) {
    let tempDate = new Date();
    let curSeason = new Date().getMonth() < 3 ? new Date().getFullYear() - 1 : new Date().getFullYear();
    const [tabName, setTabName] = useState('players');
    const [category, setCategory] = useState('batting');
    const [mode, setMode] = useState('single');
    const [season, setSeason] = useState(curSeason.toString());
    const [startSeason, setStartSeason] = useState(curSeason.toString());
    const [endSeason, setEndSeason] = useState(curSeason.toString());
    const [startDate, setStartDate] = useState(new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate()));
    const [endDate, setEndDate] = useState(new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate()));
    const [league, setLeague] = useState('0');
    const [teams, setTeams] = useState(['0']);
    const [pos, setPos] = useState('2,3,4,5,6,7,8,9,10,11');
    const [starterSub, setStarterSub] = useState(-1);
    const [ageMin, setAgeMin] = useState('15');
    const [ageMax, setAgeMax] = useState('50');
    const [qualificationFilter, setQualificationFilter] = useState(false);
    const [filterCategory, setFilterCategory] = useStateWithCallbackLazy('PA');
    const [filterOperator, setFilterOperator] = useState('>');
    const [filterValue, setFilterValue] = useStateWithCallbackLazy(0);
    const [filterObj, setFilterObj] = useState([]);
    const [withAverage, setWithAverage] = useState(true);
    const [scaleUp, setScaleUp] = useState(false);
    const [month, setMonth] = useState(['0']);
    const [half, setHalf] = useState(['-1']);
    const [excludePreTender, setExcludePreTender] = useState(false);
    const [excludeNonTender, setExcludeNonTender] = useState(false);
    const [gameType, setGameType] = useState(props.isPostseason ? ['2', '3', '4', '6'] : []);
    const [endOfSeasonRoster, setEndOfSeasonRoster] = useState(['0']);

    useEffect(() => {
        // Initialize options on leaderboards page
        let formOptions = {
            tabName: tabName,
            category: category,
            season: season,
            startSeason: startSeason,
            endSeason: endSeason,
            startDate: startDate,
            endDate: endDate,
            mode: mode,
            league: league,
            teams: teams,
            pos: pos,
            starterSub: starterSub,
            ageMin: ageMin,
            ageMax: ageMax,
            qualificationFilter: qualificationFilter,
            filterObj: filterObj,
            withAverage: withAverage,
            scaleUp: scaleUp,
            month: month,
            half: half,
            excludePreTender: excludePreTender,
            excludeNonTender: excludeNonTender,
            gameType: props.isPostseason ? ['2', '3', '4', '6'] : gameType,
            endOfSeasonRoster: endOfSeasonRoster,
        }
        props.setFormOptions(formOptions)
    }, [])

    useEffect(() => {
        let formOptions = {
            tabName: tabName,
            category: category,
            season: season,
            startSeason: startSeason,
            endSeason: endSeason,
            startDate: startDate,
            endDate: endDate,
            mode: mode,
            league: league,
            teams: teams,
            pos: pos,
            starterSub: starterSub,
            ageMin: ageMin,
            ageMax: ageMax,
            qualificationFilter: qualificationFilter,
            filterObj: filterObj,
            withAverage: withAverage,
            scaleUp: scaleUp,
            month: month,
            half: half,
            excludePreTender: excludePreTender,
            excludeNonTender: excludeNonTender,
            gameType: gameType,
            endOfSeasonRoster: endOfSeasonRoster,
        }
        props.setFormOptions(formOptions);
    }, [tabName, category, season, startSeason, endSeason, startDate, endDate,
        mode, league, teams, pos, starterSub, ageMin, ageMax, qualificationFilter, filterObj, withAverage,
        scaleUp, month, half, excludePreTender, excludeNonTender, gameType, endOfSeasonRoster])

    useEffect(() => {
        if (!canScaleUp(mode, season, startSeason, endSeason, startDate, endDate))
            setScaleUp(false);
    }, [mode, season, startSeason, endSeason, startDate, endDate])

    useEffect(() => {
        switch (category) {
            case 'batting':
                setFilterCategory('PA', () => { props.setGroupId(10) });
                if (qualificationFilter)
                    setFilterOperator('>=');
                setFilterValue(CalculateQualificationThresholdValue(1));
                break;
            case 'pitching':
                setFilterCategory('IP', () => { props.setGroupId(15) });
                if (qualificationFilter)
                    setFilterOperator('>=');
                setFilterValue(CalculateQualificationThresholdValue(2));
                break;
            case 'fielding':
                setFilterCategory('Inn', () => { props.setGroupId(14) });
                setFilterValue(0);
                setQualificationFilter(false);
                break;
            default:
                break;
        }
    }, [category])

    useEffect(() => {
        if (['separate', 'date'].includes(mode)) {
            setQualificationFilter(false);
        }
    }, [mode])

    useEffect(() => {
        if (qualificationFilter) {
            switch (category) {
                case 'batting':
                    setFilterCategory('PA', () => { props.setGroupId(10) });
                    if (qualificationFilter)
                        setFilterOperator('>=');
                    setFilterValue(CalculateQualificationThresholdValue(1));
                    break;
                case 'pitching':
                    setFilterCategory('IP', () => { props.setGroupId(15) });
                    if (qualificationFilter)
                        setFilterOperator('>=');
                    setFilterValue(CalculateQualificationThresholdValue(2));
                    break;
                default:
                    break;
            }
        }
    }, [qualificationFilter, season, startSeason, endSeason, props.qualifierThresholds])

    useEffect(() => {
        if (['teams', 'mlb'].includes(tabName)) {
            setQualificationFilter(false);
            setWithAverage(false);
            setExcludePreTender(false);
            setExcludeNonTender(false);
        }
    }, [tabName])

    function CalculateQualificationThresholdValue(qualifierId) {
        if (qualificationFilter && props.qualifierThresholds.length > 0) {
            return props.qualifierThresholds
                .filter(qualifierThreshold =>
                    qualifierThreshold.qualifierId === qualifierId
                    && ['cumulative', 'single'].includes(mode) &&
                    (
                        (
                            mode === 'cumulative'
                            && qualifierThreshold.season >= Number.parseInt(startSeason)
                            && qualifierThreshold.season <= Number.parseInt(endSeason)
                        )
                        ||
                        (
                            mode === 'single'
                            && qualifierThreshold.season.toString() === season
                        )
                    )
                )
                .map(qualifierThreshold => qualifierThreshold.thresholdValue)
                .reduce((prev, cur) => prev + cur, 0);
        }
        return 0;
    }

    function canScaleUp(curMode, curSeason, curStartSeason, curEndSeason, curStartDate, curEndDate) {
        return (
            (curMode === 'single' && curSeason === '2020') ||
            (curMode === 'date' && curStartDate <= new Date('7/23/2020') && curEndDate >= new Date('9/27/2020')) ||
            (['cumulative', 'separate'].includes(curMode) && curStartSeason <= 2020 && curEndSeason >= 2020)
        )
    }

    function isPartialScaledUpSeason(curMode, curStartDate, curEndDate) {
        return curMode === 'date' &&
            (
                (curStartDate >= new Date('7/23/2020') && curStartDate <= new Date('9/27/2020')) ||
                (curEndDate >= new Date('7/23/2020') && curEndDate <= new Date('9/27/2020'))
            )
    }

    let seasonOptions = [];
    for (let i = curSeason; i >= 2002; i--) {
        seasonOptions.push(
            <option key={i} value={i}>{i}</option>
        )
    };

    let qualificationFilterRadioOptions = [];
    if (qualificationFilter) {
        qualificationFilterRadioOptions.push(
            <Form.Check
                custom
                inline
                name='qualificationFilter'
                label=
                {
                    'Qualified for ' +
                    (
                        category === 'pitching'
                            ? 'ERA'
                            : 'Batting'
                    ) + ' Title'
                }
                type='radio'
                id='custom-inline-radio-qualification'
                defaultChecked
                onChange={(event) => {
                    if (event.target.value) {
                        setQualificationFilter(true)
                    }
                }}
            />,
            <Form.Check
                custom
                inline
                name='qualificationFilter'
                label='Custom Filter'
                type='radio'
                id='custom-inline-radio-custom-filter'
                onChange={(event) => {
                    if (event.target.value) {
                        setQualificationFilter(false)
                    }
                }}
            />
        )
    } else {
        qualificationFilterRadioOptions.push(
            <Form.Check
                custom
                inline
                name='qualificationFilter'
                label=
                {
                    'Qualified for ' +
                    (
                        category === 'pitching'
                            ? 'ERA'
                            : 'Batting'
                    ) + ' Title'
                }
                type='radio'
                id='custom-inline-radio-qualification'
                onChange={(event) => {
                    if (event.target.value) {
                        setQualificationFilter(true)
                    }
                }}
            />,
            <Form.Check
                custom
                inline
                name='qualificationFilter'
                label='Custom Filter'
                type='radio'
                id='custom-inline-radio-custom-filter'
                defaultChecked
                onChange={(event) => {
                    if (event.target.value) {
                        setQualificationFilter(false)
                    }
                }}
            />
        )
    }

    let filterCategories = [];
    switch (category) {
        case 'batting':
            filterCategories.push(
                <option selected={filterCategory === 'PA' ? 'selected' : false} key={1} value={'PA'}>PA</option>,
                <option selected={filterCategory === 'bG' ? 'selected' : false} key={2} value={'bG'}>G</option>,
                <option selected={filterCategory === 'bGS' ? 'selected' : false} key={3} value={'bGS'}>GS</option>,
                <option selected={filterCategory === 'AB' ? 'selected' : false} key={4} value={'AB'}>AB</option>
            )
            break;
        case 'pitching':
            filterCategories.push(
                <option selected={filterCategory === 'IP' ? 'selected' : false} key={1} value={'IP'}>IP</option>,
                <option selected={filterCategory === 'pG' ? 'selected' : false} key={2} value={'pG'}>G</option>,
                <option selected={filterCategory === 'pGS' ? 'selected' : false} key={3} value={'pGS'}>GS</option>,
                <option selected={filterCategory === 'RAPP' ? 'selected' : false} key={4} value={'RAPP'}>RAPP</option>,
                <option selected={filterCategory === 'SV' ? 'selected' : false} key={5} value={'SV'}>SV</option>,
                <option selected={filterCategory === 'HLD' ? 'selected' : false} key={6} value={'HLD'}>HLD</option>,
                <option selected={filterCategory === 'BF' ? 'selected' : false} key={7} value={'BF'}>BF</option>,
                <option selected={filterCategory === 'LC' ? 'selected' : false} key={8} value={'LC'}>LC</option>,
                <option selected={filterCategory === 'SvOpp' ? 'selected' : false} key={9} value={'SvOpp'}>SvOpp</option>
            )
            break;
        case 'fielding':
            filterCategories.push(
                <option selected={filterCategory === 'Inn' ? 'selected' : false} key={1} value={'Inn'}>Defensive Innings</option>,
                <option selected={filterCategory === 'fG' ? 'selected' : false} key={2} value={'fG'}>G</option>,
                <option selected={filterCategory === 'fGS' ? 'selected' : false} key={3} value={'fGS'}>GS</option>
            )
            break;
    }

    return (
        <React.Fragment>
            {
                !props.isPostseason &&
                <>
                    <Navbar bg='light' variant='light' className='leaderboardsNav'>
                        <Nav>
                            <Nav.Link className={tabName === 'players' ? 'selectedTab' : null} onClick={() => setTabName('players')}>Player Stats</Nav.Link>
                            <Nav.Link className={tabName === 'teams' ? 'selectedTab' : null} onClick={() => setTabName('teams')}>Team Stats</Nav.Link>
                            <Nav.Link className={tabName === 'mlb' ? 'selectedTab' : null} onClick={() => setTabName('mlb')}>MLB Stats</Nav.Link>
                        </Nav>
                    </Navbar>
                    <br />
                </>
            }

            <Navbar bg='light' variant='light' className='leaderboardsNav'>
                <Nav>
                    <Nav.Link className={category === 'batting' ? 'selectedTab' : null} onClick={() => setCategory('batting')}>Batting</Nav.Link>
                    <Nav.Link className={category === 'pitching' ? 'selectedTab' : null} onClick={() => setCategory('pitching')}>Pitching</Nav.Link>
                    <Nav.Link className={category === 'fielding' ? 'selectedTab' : null} onClick={() => setCategory('fielding')}>Fielding</Nav.Link>
                </Nav>
            </Navbar>
            <br />

            <Row>
                <b className='label'>Mode: </b>
                <Form>
                    <div key={'custom-inline-radio'}>
                        <Form.Check
                            custom
                            inline
                            name='mode'
                            label='Single Season'
                            type='radio'
                            id='custom-inline-radio-single'
                            defaultChecked
                            onChange={(event) => {
                                if (event.target.value) {
                                    setMode('single')
                                }
                            }}
                        />
                        <Form.Check
                            custom
                            inline
                            name='mode'
                            label='Multiple Seasons - Cumulative'
                            type='radio'
                            id='custom-inline-radio-cumulative'
                            onChange={(event) => {
                                if (event.target.value) {
                                    setMode('cumulative')
                                }
                            }}
                        />
                        <Form.Check
                            custom
                            inline
                            name='mode'
                            label='Multiple Seasons - Separate'
                            type='radio'
                            id='custom-inline-radio-separate'
                            onChange={(event) => {
                                if (event.target.value) {
                                    setMode('separate')
                                }
                            }}
                        />
                        {
                            !props.isPostseason &&
                            <Form.Check
                                custom
                                inline
                                name='mode'
                                label='Date Range'
                                type='radio'
                                id='custom-inline-radio-date'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setMode('date')
                                    }
                                }}
                            />
                        }
                    </div>
                </Form>
            </Row>
            <br />

            <Row>
                {
                    mode === 'single' &&
                    <React.Fragment>
                        <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Season: </Form.Label>
                        <Form.Control style={{ width: '100px' }} as='select'
                            defaultValue={season} onChange={(event) => setSeason(event.target.value)}>
                            {seasonOptions}
                        </Form.Control>
                        {
                            !props.isPostseason &&
                            <>
                                <MonthSelector setMonth={setMonth} />
                                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>1st Half/2nd Half: </Form.Label>
                                <Form.Control style={{ width: '187px' }} as='select' defaultValue={half} onChange={(event) => setHalf(event.target.value)}>
                                    <option key='N/A' value='-1'>N/A</option>
                                    <option key='Pre-All Star Game' value='0'>Pre-All Star Game</option>
                                    <option key='Post-All Star Game' value='1'>Post-All Star Game</option>
                                </Form.Control>
                            </>
                        }
                    </React.Fragment>
                }
                {
                    (mode === 'cumulative' || mode === 'separate') &&
                    <React.Fragment>
                        <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Start Season: </Form.Label>
                        <Form.Control style={{ width: '100px' }} as='select'
                            defaultValue={startSeason} onChange={(event) => setStartSeason(event.target.value)}>
                            {seasonOptions}
                        </Form.Control>
                        <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>End Season: </Form.Label>
                        <Form.Control style={{ width: '100px' }} as='select'
                            defaultValue={endSeason} onChange={(event) => setEndSeason(event.target.value)}>
                            {seasonOptions}
                        </Form.Control>
                        {
                            !props.isPostseason &&
                            <>
                                <MonthSelector setMonth={setMonth} />
                                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>1st Half/2nd Half: </Form.Label>
                                <Form.Control style={{ width: '187px' }} as='select' defaultValue={half} onChange={(event) => setHalf(event.target.value)}>
                                    <option key='N/A' value='-1'>N/A</option>
                                    <option key='Pre-All Star Game' value='0'>Pre-All Star Game</option>
                                    <option key='Post-All Star Game' value='1'>Post-All Star Game</option>
                                </Form.Control>
                            </>
                        }
                    </React.Fragment>
                }
                {
                    mode === 'date' &&
                    <React.Fragment>
                        <b className='label' style={{ paddingRight: '25px' }}>From: </b>
                        <DatePicker setDate={(date) => setStartDate(date)} maxDate={endDate} startDate={startDate} />
                        <b classname='label' style={{ padding: '0px 25px' }}>To: </b>
                        <DatePicker setDate={(date) => setEndDate(date)} minDate={startDate} endDate={endDate} />
                    </React.Fragment>
                }
                {
                    canScaleUp(mode, season, startSeason, endSeason, startDate, endDate) && !props.isPostseason &&
                    <Row style={{ marginLeft: '10px' }}>
                        <Form.Check
                            name='scaleUp'
                            label='Scale Up?'
                            type='checkbox'
                            onChange={(event) => {
                                setScaleUp(event.target.checked);
                            }}
                        />
                    </Row>
                }
                {
                    !canScaleUp(mode, season, startSeason, endSeason, startDate, endDate) && !props.isPostseason &&
                    isPartialScaledUpSeason(mode, startDate, endDate) &&
                    <p style={{ color: 'rgb(220, 53, 69)', textAlign: 'center', margin: '0', marginLeft: '5px' }}>
                        Can not scale up partial season
                    </p>
                }
            </Row>
            <br />
            {
                props.isPostseason &&
                <>
                    <Row>
                        <MultiSelect
                            list={props.isPostseason
                                ? props.gameTypeFilter?.formInputs?.selectOptions.filter(option => !['All Star Game', 'Regular Season'].includes(option.label)) ?? []
                                : props.gameTypeFilter?.formInputs?.selectOptions ?? []}
                            label={props.gameTypeFilter?.formInputs?.formLabel ?? 'value'}
                            selectedItems={gameType}
                            setSelectedItems={(selectedValues) => setGameType(selectedValues)}
                        />
                    </Row>
                    <br />
                </>
            }
            <Row>
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>League: </Form.Label>
                <Form.Control style={{ width: '100px' }} as='select' defaultValue={league} onChange={(event) => setLeague(event.target.value)}>
                    <option key='All' value='0'>All</option>
                    <option key='AL' value='1'>AL</option>
                    <option key='NL' value='2'>NL</option>
                </Form.Control>
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Team: </Form.Label>
                <TeamSelector setTeams={setTeams} />
            </Row>
            <br />
            {
                category !== 'pitching' &&
                <React.Fragment>
                    <Row>
                        <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Primary Position: </Form.Label>
                        <Form.Control style={{ width: '250px' }} as='select' defaultValue={pos} onChange={(event) => setPos(event.target.value)}>
                            <option key='0' value='2,3,4,5,6,7,8,9,10,11'>Batter</option>
                            <option key='1' value='14,15'>Pitcher</option>
                            <option key='2' value='2'>Catcher</option>
                            <option key='3' value='3'>First Baseman</option>
                            <option key='4' value='4'>Second Baseman</option>
                            <option key='5' value='5'>Third Baseman</option>
                            <option key='6' value='6'>Shortstop</option>
                            <option key='7' value='7'>Left Fielder</option>
                            <option key='8' value='8'>Center Fielder</option>
                            <option key='9' value='9'>Right Fielder</option>
                            <option key='10' value='7,8,9'>Outfielder</option>
                            <option key='11' value='11'>Designated Hitter</option>
                        </Form.Control>
                    </Row>
                    <br />
                </React.Fragment>
            }
            {
                !props.isPostseason && (mode === 'single' || mode === 'separate') &&
                <>
                    <Row>
                        <RosterStatusSelector setRosterStatus={setEndOfSeasonRoster} />
                    </Row>
                    <br />
                </>
            }
            <Row>
                <b className='label'>View Data: </b>
                <div key={'custom-inline-radio'}>
                    <Form.Check
                        custom
                        inline
                        name='starterSub'
                        label='All'
                        type='radio'
                        id='custom-inline-radio-all'
                        defaultChecked
                        onChange={(event) => {
                            if (event.target.value) {
                                setStarterSub(-1)
                            }
                        }}
                    />
                    <Form.Check
                        custom
                        inline
                        name='starterSub'
                        label='As Starter'
                        type='radio'
                        id='custom-inline-radio-starter'
                        onChange={(event) => {
                            if (event.target.value) {
                                setStarterSub(1)
                            }
                        }}
                    />
                    <Form.Check
                        custom
                        inline
                        name='starterSub'
                        label='As Sub/Reliever'
                        type='radio'
                        id='custom-inline-radio-sub'
                        onChange={(event) => {
                            if (event.target.value) {
                                setStarterSub(0)
                            }
                        }}
                    />
                </div>
            </Row>
            <br />

            <Row>
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Player Age From: </Form.Label>
                <Form.Control style={{ width: '100px' }} as='input' type='number' defaultValue={'15'} onChange={(event) => setAgeMin(event.target.value)} />
                <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Player Age To: </Form.Label>
                <Form.Control style={{ width: '100px' }} as='input' type='number' defaultValue={'50'} onChange={(event) => setAgeMax(event.target.value)} />

            </Row>
            <br />

            {
                !['teams', 'mlb'].includes(tabName) &&
                <React.Fragment>
                    <Row>
                        <b className='label'>Display Average: </b>
                        <div key={'default-checkbox-with-average'}>
                            <Form.Check
                                name='displayAverage'
                                label='Display Average'
                                type='checkbox'
                                id='default-checkbox-with-average'
                                defaultChecked
                                onChange={(event) => {
                                    setWithAverage(event.target.checked);
                                }}
                            />
                        </div>
                    </Row>
                    <br />
                    {
                        !props.isPostseason &&
                        <>
                            <Row>
                                <b className='label'>Contract Filters: </b>
                                <div key={'default-checkbox-exclude-pre-tender'} style={{ marginRight: '15px' }}>
                                    <Form.Check
                                        name='excludePreTenderSigning'
                                        label='Exclude Pre-Tender Signings'
                                        type='checkbox'
                                        id='default-checkbox-exclude-pre-tender'
                                        onChange={(event) => {
                                            setExcludePreTender(event.target.checked);
                                        }}
                                    />
                                </div>
                                <div key={'default-checkbox-exclude-non-tender'} style={{ marginRight: '15px' }}>
                                    <Form.Check
                                        name='excludeNonTendered'
                                        label='Exclude Players Who Were Non-Tendered'
                                        type='checkbox'
                                        id='default-checkbox-exclude-non-tender'
                                        onChange={(event) => {
                                            setExcludeNonTender(event.target.checked);
                                        }}
                                    />
                                </div>
                            </Row>
                            <br />
                        </>
                    }
                </React.Fragment>
            }

            <p style={{ fontWeight: 700 }}>Playing Time Filter</p>
            <div style={{ width: '100%', backgroundColor: 'lightgrey', border: 'solid 1px black' }}>
                {
                    !['date', 'separate'].includes(mode) && !['teams', 'mlb'].includes(tabName) && category !== 'fielding' &&
                    !props.isPostseason &&
                    <div className='row filter' style={{ marginTop: '10px' }}>
                        <div className='col-md-12'>
                            <div key={'custom-inline-radio'}>
                                {qualificationFilterRadioOptions}
                            </div>
                        </div>
                    </div>
                }
                <div className='row filter'>
                    <div className='col-md-5'>
                        <p>Stat</p>
                    </div>
                    <div className='col-md-2'>
                        <p>Operator</p>
                    </div>
                    <div className='col-md-5'>
                        <p>Value</p>
                    </div>
                </div>
                {
                    !qualificationFilter &&
                    <PlayingTimeFilterWrapper
                        filterOperator={filterOperator}
                        filterValue={filterValue}
                        filterCategory={filterCategory}
                        setFilterCategory={setFilterCategory}
                        setFilterOperator={setFilterOperator}
                        setFilterValue={setFilterValue}
                        filterCategories={filterCategories}
                        setFilterObj={setFilterObj}
                        category={category}
                        tabName={tabName}
                        season={season}
                        startSeason={startSeason}
                        endSeason={endSeason}
                        startDate={startDate}
                        endDate={endDate}
                        mode={mode}
                        league={league}
                        teams={teams}
                        pos={pos}
                        starterSub={starterSub}
                        ageMin={ageMin}
                        ageMax={ageMax}
                        qualificationFilter={qualificationFilter}
                        filterObj={filterObj}
                        withAverage={withAverage}
                        scaleUp={scaleUp}
                        month={month}
                        half={half}
                        excludePreTender={excludePreTender}
                        excludeNonTender={excludeNonTender}
                        endOfSeasonRoster={endOfSeasonRoster}
                        setFormOptions={props.setFormOptions}
                    />
                }
                {
                    qualificationFilter &&
                    <PlayingTimeFilterWrapper
                        filterOperator={filterOperator}
                        filterValue={filterValue}
                        filterCategory={filterCategory}
                        setFilterCategory={setFilterCategory}
                        setFilterOperator={setFilterOperator}
                        setFilterValue={setFilterValue}
                        filterCategories={filterCategories}
                        setFilterObj={setFilterObj}
                        category={category}
                        tabName={tabName}
                        season={season}
                        startSeason={startSeason}
                        endSeason={endSeason}
                        startDate={startDate}
                        endDate={endDate}
                        mode={mode}
                        league={league}
                        teams={teams}
                        pos={pos}
                        starterSub={starterSub}
                        ageMin={ageMin}
                        ageMax={ageMax}
                        qualificationFilter={qualificationFilter}
                        filterObj={filterObj}
                        withAverage={withAverage}
                        scaleUp={scaleUp}
                        month={month}
                        half={half}
                        excludePreTender={excludePreTender}
                        excludeNonTender={excludeNonTender}
                        endOfSeasonRoster={endOfSeasonRoster}
                        setFormOptions={props.setFormOptions}
                    />
                }
                <br />
            </div>
            <br />
        </React.Fragment>
    )
}

export default LeaderboardForm;