import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from '../Common/DatePicker';


function RankReportForm(props) {
    let curSeason = new Date().getMonth() < 3 ? new Date().getFullYear() - 1 : new Date().getFullYear();
    
    const [mode, setMode] = useState('single');
    const [season, setSeason] = useState(curSeason.toString());
    const [startSeason, setStartSeason] = useState(curSeason.toString());
    const [endSeason, setEndSeason] = useState(curSeason.toString());
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [qualificationFilter, setQualificationFilter] = useState(false);
    const [filterCategoryOptions, setFilterCategoryOptions] = useState([]);
    const [filterCategory, setFilterCategory] = useState('PA');
    const [filterValue, setFilterValue] = useState(0);
    const [filterOperator, setFilterOperator] = useState('>');
    const [filterObj, setFilterObj] = useState({});
    const [starterSub, setStarterSub] = useState(-1);
    const [rankFormat, setRankFormat] = useState('ranks');

    useEffect(() => {
        // Initialize options on rank report page
        let formOptions = {
            mode: mode,
            season: season,
            startSeason: startSeason,
            endSeason: endSeason,
            startDate: startDate,
            endDate: endDate,
            qualificationFilter: qualificationFilter,
            filterObj: filterObj,
            starterSub: starterSub,
            rankFormat: rankFormat,
        }
        props.setFormOptions(formOptions)
    }, [])

    useEffect(() => {
        let formOptions = {
            mode: mode,
            season: season,
            startSeason: startSeason,
            endSeason: endSeason,
            startDate: startDate,
            endDate: endDate,
            qualificationFilter: qualificationFilter,
            filterObj: filterObj,
            starterSub: starterSub,
            rankFormat: rankFormat,
        }
        props.setFormOptions(formOptions)
    }, [
        mode,
        season,
        startSeason,
        endSeason,
        startDate,
        endDate,
        qualificationFilter,
        filterObj,
        starterSub,
        rankFormat,
    ])

    useEffect(() => {
        let filterCategories = [];
        switch (props.category) {
            case 'batting':
                setFilterCategory('PA');
                if (qualificationFilter)
                    setFilterOperator('>=');
                setFilterValue(CalculateQualificationThresholdValue(1));
                filterCategories.push(
                    <option selected={filterCategory === 'PA' ? 'selected' : false} key={1} value={'PA'}>PA</option>,
                    <option selected={filterCategory === 'G' ? 'selected' : false} key={2} value={'G'}>G</option>,
                    <option selected={filterCategory === 'GS' ? 'selected' : false} key={3} value={'GS'}>GS</option>,
                    <option selected={filterCategory === 'AB' ? 'selected' : false} key={4} value={'AB'}>AB</option>
                );
                break;
            case 'pitching':
                setFilterCategory('IP');
                if (qualificationFilter)
                    setFilterOperator('>=');
                setFilterValue(CalculateQualificationThresholdValue(2));
                filterCategories.push(
                    <option selected={filterCategory === 'IP' ? 'selected' : false} key={1} value={'IP'}>IP</option>,
                    <option selected={filterCategory === 'G' ? 'selected' : false} key={2} value={'G'}>G</option>,
                    <option selected={filterCategory === 'GS' ? 'selected' : false} key={3} value={'GS'}>GS</option>,
                    <option selected={filterCategory === 'BF' ? 'selected' : false} key={4} value={'BF'}>BF</option>
                );
                break;
            case 'relief':
                setFilterCategory('IP');
                setQualificationFilter(false);
                setFilterValue(0);
                filterCategories.push(
                    <option selected={filterCategory === 'IP' ? 'selected' : false} key={1} value={'IP'}>IP</option>,
                    <option selected={filterCategory === 'G' ? 'selected' : false} key={2} value={'G'}>G</option>,
                    <option selected={filterCategory === 'RAPP' ? 'selected' : false} key={3} value={'RAPP'}>RAPP</option>,
                    <option selected={filterCategory === 'SV' ? 'selected' : false} key={4} value={'SV'}>SV</option>,
                    <option selected={filterCategory === 'HLD' ? 'selected' : false} key={5} value={'HLD'}>HLD</option>,
                    <option selected={filterCategory === 'BF' ? 'selected' : false} key={6} value={'BF'}>BF</option>,
                    <option selected={filterCategory === 'LC' ? 'selected' : false} key={7} value={'LC'}>LC</option>,
                    <option selected={filterCategory === 'SvOpp' ? 'selected' : false} key={8} value={'SvOpp'}>SvOpp</option>
                );
                break;
        }
        setFilterCategoryOptions(filterCategories);
    }, [props.category])

    let seasonOptions = []
    for (let i = curSeason; i >= 2002; i--) {
        seasonOptions.push(
            <option key={i} value={i}>{i}</option>
        )
    }

    

    useEffect(() => {
        let val = 'IP' === filterCategory ? Number(filterValue) : Number(filterValue).toFixed(0);
        let newFilterObj = {};
        switch (filterCategory) {
            case 'PA':
                newFilterObj.valuePA = val;
                newFilterObj.opPA = filterOperator;
                break;
            case 'IP':
                newFilterObj.valueIP = val;
                newFilterObj.opIP = filterOperator;
                break;
            case 'RAPP':
                newFilterObj.valueRAPP = val;
                newFilterObj.opRAPP = filterOperator;
                break;
            case 'G':
                newFilterObj.valueG = val;
                newFilterObj.opG = filterOperator;
                break;
            case 'GS':
                newFilterObj.valueGS = val;
                newFilterObj.opGS = filterOperator;
                break;
            case 'SV':
                newFilterObj.valueSV = val;
                newFilterObj.opSV = filterOperator;
                break;
            case 'HLD':
                newFilterObj.valueHLD = val;
                newFilterObj.opHLD = filterOperator;
                break;
            case 'AB':
                newFilterObj.valueAB = val;
                newFilterObj.opAB = filterOperator;
                break;
            case 'BF':
                newFilterObj.valueBF = val;
                newFilterObj.opBF = filterOperator;
                break;
            case 'LC':
                newFilterObj.valueLC = val;
                newFilterObj.opLC = filterOperator;
                break;
            case 'SvOpp':
                newFilterObj.valueSvOpp = val;
                newFilterObj.opSvOpp = filterOperator;
                break;
        }
        setFilterObj(newFilterObj);
    }, [filterCategory, filterOperator, filterValue])

    useEffect(() => {
        if (['separate', 'date'].includes(mode)) {
            setQualificationFilter(false);
        }
    }, [mode])

    useEffect(() => {
        if (qualificationFilter) {
            switch (props.category) {
                case 'batting':
                    setFilterCategory('PA', () => { props.setGroupId(10) });
                    if (qualificationFilter)
                        setFilterOperator('>=');
                    setFilterValue(CalculateQualificationThresholdValue(1));
                    break;
                case 'pitching':
                    setFilterCategory('IP', () => { props.setGroupId(15) });
                    if (qualificationFilter)
                        setFilterOperator('>=');
                    setFilterValue(CalculateQualificationThresholdValue(2));
                    break;
                default:
                    break;
            }
        }
    }, [qualificationFilter, season, startSeason, endSeason, props.qualifierThresholds])

    function CalculateQualificationThresholdValue(qualifierId) {
        if (qualificationFilter && props.qualifierThresholds.length > 0) {
            return props.qualifierThresholds
                .filter(qualifierThreshold =>
                    qualifierThreshold.qualifierId === qualifierId &&
                    (
                        (
                            mode === 'cumulative'
                            && qualifierThreshold.season >= Number.parseInt(startSeason)
                            && qualifierThreshold.season <= Number.parseInt(endSeason)
                        )
                        ||
                        (
                            mode === 'single'
                            && qualifierThreshold.season.toString() === season
                        )
                    )
                )
                .map(qualifierThreshold => qualifierThreshold.thresholdValue)
                .reduce((prev, cur) => prev + cur, 0);
        }
        return 0;
    }

    let qualificationFilterRadioOptions = [];
    if (qualificationFilter) {
        qualificationFilterRadioOptions.push(
            <Form.Check
                custom
                inline
                name='qualificationFilter'
                label=
                {
                    'Qualified for ' +
                    (
                        props.category === 'pitching'
                            ? 'ERA'
                            : 'Batting'
                    ) + ' Title'
                }
                type='radio'
                id='custom-inline-radio-qualification'
                defaultChecked
                onChange={(event) => {
                    if (event.target.value) {
                        setQualificationFilter(true)
                    }
                }}
            />,
            <Form.Check
                custom
                inline
                name='qualificationFilter'
                label='Custom Filter'
                type='radio'
                id='custom-inline-radio-custom-filter'
                onChange={(event) => {
                    if (event.target.value) {
                        setQualificationFilter(false)
                    }
                }}
            />
        )
    } else {
        qualificationFilterRadioOptions.push(
            <Form.Check
                custom
                inline
                name='qualificationFilter'
                label=
                {
                    'Qualified for ' +
                    (
                        props.category === 'pitching'
                            ? 'ERA'
                            : 'Batting'
                    ) + ' Title'
                }
                type='radio'
                id='custom-inline-radio-qualification'
                onChange={(event) => {
                    if (event.target.value) {
                        setQualificationFilter(true)
                    }
                }}
            />,
            <Form.Check
                custom
                inline
                name='qualificationFilter'
                label='Custom Filter'
                type='radio'
                id='custom-inline-radio-custom-filter'
                defaultChecked
                onChange={(event) => {
                    if (event.target.value) {
                        setQualificationFilter(false)
                    }
                }}
            />
        )
    }

    return (
        <React.Fragment>
            <div className='row'>
                <b className='label'>Mode: </b>
                <Form>
                    <div key={'custom-inline-radio'}>
                        <Form.Check
                            custom
                            inline
                            name='mode'
                            label='Single Season'
                            type='radio'
                            id='custom-inline-radio-single'
                            defaultChecked
                            onChange={(event) => {
                                if (event.target.value) {
                                    setMode('single')
                                }
                            }}
                        />
                        <Form.Check
                            custom
                            inline
                            name='mode'
                            label='Multiple Seasons - Cumulative'
                            type='radio'
                            id='custom-inline-radio-cumulative'
                            onChange={(event) => {
                                if (event.target.value) {
                                    setMode('cumulative')
                                }
                            }}
                        />
                        <Form.Check
                            custom
                            inline
                            name='mode'
                            label='Multiple Seasons - Separate'
                            type='radio'
                            id='custom-inline-radio-separate'
                            onChange={(event) => {
                                if (event.target.value) {
                                    setMode('separate')
                                }
                            }}
                        />
                        <Form.Check
                            custom
                            inline
                            name='mode'
                            label='Date Range'
                            type='radio'
                            id='custom-inline-radio-date'
                            onChange={(event) => {
                                if (event.target.value) {
                                    setMode('date')
                                }
                            }}
                        />
                    </div>
                </Form>
            </div>
            <br />

            <div className='row'>
                {
                    mode === 'single' &&
                    <React.Fragment>
                        <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Season: </Form.Label>
                        <Form.Control style={{ width: '100px' }} as='select'
                            defaultValue={season} onChange={(event) => setSeason(event.target.value)}>
                            {seasonOptions}
                        </Form.Control>
                    </React.Fragment>
                }
                {
                    (mode === 'cumulative' || mode === 'separate') &&
                    <React.Fragment>
                        <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>Start Season: </Form.Label>
                        <Form.Control style={{ width: '100px' }} as='select'
                            defaultValue={startSeason} onChange={(event) => setStartSeason(event.target.value)}>
                            {seasonOptions}
                        </Form.Control>
                        <Form.Label style={{ fontWeight: 700, marginBottom: 0, paddingRight: '10px' }}>End Season: </Form.Label>
                        <Form.Control style={{ width: '100px' }} as='select'
                            defaultValue={endSeason} onChange={(event) => setEndSeason(event.target.value)}>
                            {seasonOptions}
                        </Form.Control>
                    </React.Fragment>
                }
                {
                    mode === 'date' &&
                    <React.Fragment>
                        <b className='label' style={{ paddingRight: '25px' }}>From: </b>
                        <DatePicker setDate={(date) => setStartDate(date)} maxDate={endDate} startDate={startDate} />
                        <b classname='label' style={{ padding: '0px 25px' }}>To: </b>
                        <DatePicker setDate={(date) => setEndDate(date)} minDate={startDate} endDate={endDate} />
                    </React.Fragment>
                }
            </div>
            <br />

            <p style={{ fontWeight: 700 }}>Playing Time Filter</p>
            <div style={{ width: '100%', backgroundColor: 'lightgrey', border: 'solid 1px black' }}>
                {
                    !['date', 'separate'].includes(mode) && props.category !== 'relief' &&
                    <div className='row filter' style={{ marginTop: '10px' }}>
                        <div className='col-md-12'>
                            <div key={'custom-inline-radio'}>
                                {qualificationFilterRadioOptions}
                            </div>
                        </div>
                    </div>
                }
                {
                    !qualificationFilter &&

                    <div className='row filter'>
                        <div className='col-md-5'>
                            <p>Stat</p>
                            <Form.Control as='select' onChange={(event) => setFilterCategory(event.target.value)}>
                                {filterCategoryOptions}
                            </Form.Control>
                        </div>
                        <div className='col-md-2'>
                            <p>Operator</p>
                            <Form.Control as='select' onChange={(event) => setFilterOperator(event.target.value)}>
                                <option selected={filterOperator === '>' ? 'selected' : false} key={1} value={'>'}>{'>'}</option>
                                <option selected={filterOperator === '>=' ? 'selected' : false} key={2} value={'>='}>{'>='}</option>
                                <option selected={filterOperator === '=' ? 'selected' : false} key={3} value={'='}>{'='}</option>
                                <option selected={filterOperator === '<=' ? 'selected' : false} key={4} value={'<='}>{'<='}</option>
                                <option selected={filterOperator === '<' ? 'selected' : false} key={5} value={'<'}>{'<'}</option>
                                <option selected={filterOperator === '!=' ? 'selected' : false} key={6} value={'!='}>{'!='}</option>
                            </Form.Control>
                        </div>
                        <div className='col-md-5'>
                            <p>Value</p>
                            <Form.Control as='input' type='number' step='1' value={filterValue} onChange={(event) => setFilterValue(event.target.value)} />
                        </div>
                        {/*
                        <div className='col-md-2'>
                            <p style={{ color: 'lightgrey' }}>.</p>
                            <Button variant='info'><img src={'./images/Filter.png'} type='button' />Add Criteria</Button>
                        </div>
                        */}
                    </div>
                }
                {
                    qualificationFilter &&
                    <fieldset disabled>
                        <div className='row filter'>
                            <div className='col-md-5'>
                                <p>Stat</p>
                                <Form.Control as='select' onChange={(event) => setFilterCategory(event.target.value)}>
                                    {filterCategoryOptions}
                                </Form.Control>
                            </div>
                            <div className='col-md-2'>
                                <p>Operator</p>
                                <Form.Control as='select' onChange={(event) => setFilterOperator(event.target.value)}>
                                    <option selected={filterOperator === '>' ? 'selected' : false} key={1} value={'>'}>{'>'}</option>
                                    <option selected={filterOperator === '>=' ? 'selected' : false} key={2} value={'>='}>{'>='}</option>
                                    <option selected={filterOperator === '=' ? 'selected' : false} key={3} value={'='}>{'='}</option>
                                    <option selected={filterOperator === '<=' ? 'selected' : false} key={4} value={'<='}>{'<='}</option>
                                    <option selected={filterOperator === '<' ? 'selected' : false} key={5} value={'<'}>{'<'}</option>
                                    <option selected={filterOperator === '!=' ? 'selected' : false} key={6} value={'!='}>{'!='}</option>
                                </Form.Control>
                            </div>
                            <div className='col-md-5'>
                                <p>Value</p>
                                <Form.Control as='input' type='number' step='0.01' value={filterValue} onChange={(event) => setFilterValue(event.target.value)} />
                            </div>
                            {/*
                            <div className='col-md-2'>
                                <p style={{ color: 'lightgrey' }}>.</p>
                                <Button variant='info'><img src={'./images/Filter.png'} type='button' />Add Criteria</Button>
                            </div>
                            */}
                        </div>
                    </fieldset>
                }
                <br />
            </div>
            <br />

            {
                props.category !== 'batting' &&
                <React.Fragment>
                    <div className='row'>
                        <b className='label'>View Data: </b>
                        <div key={'custom-inline-radio'}>
                            <Form.Check
                                custom
                                inline
                                name='scope'
                                label='All'
                                type='radio'
                                id='custom-inline-radio-all'
                                defaultChecked
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setStarterSub(-1)
                                    }
                                }}
                            />
                            <Form.Check
                                custom
                                inline
                                name='scope'
                                label='As Starter'
                                type='radio'
                                id='custom-inline-radio-starter'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setStarterSub(1)
                                    }
                                }}
                            />
                            <Form.Check
                                custom
                                inline
                                name='scope'
                                label='As Sub/Reliever'
                                type='radio'
                                id='custom-inline-radio-sub'
                                onChange={(event) => {
                                    if (event.target.value) {
                                        setStarterSub(0)
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <br />
                </React.Fragment>
            }

            <div className='row'>
                <b className='label'>Format: </b>
                <Form>
                    <div key={'custom-inline-radio'}>
                        <Form.Check
                            custom
                            inline
                            name='rankFormat'
                            label='Ranks'
                            type='radio'
                            id='custom-inline-radio-ranks'
                            defaultChecked
                            onChange={(event) => {
                                if (event.target.value) {
                                    setRankFormat('ranks')
                                }
                            }}
                        />
                        <Form.Check
                            custom
                            inline
                            name='rankFormat'
                            label='Percentile Rank'
                            type='radio'
                            id='custom-inline-radio-percentile'
                            onChange={(event) => {
                                if (event.target.value) {
                                    setRankFormat('percentile')
                                }
                            }}
                        />
                    </div>
                </Form>
            </div>
            <br />
        </React.Fragment>
    )
}

export default RankReportForm;